import React from "react"
import { graphql } from "gatsby"
import { FormattedMessage, useIntl } from "react-intl"
import { getSrc, getSrcSet } from "gatsby-plugin-image"
import { Parallax } from "react-parallax"

import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Layout from "../layouts"

import { smartReplacements } from "../utils/text-properties"

import DoubleBorderBox from "../components/DoubleBorderBox"
import LocalizedLink from "../components/LocalizedLink"

const NotFoundPage = props => {
  // props
  const {
    data: {
      allContentfulNotFound: { nodes: notFounds },
    },
    pageContext: { locale },
  } = props

  // hooks
  const { formatMessage } = useIntl()

  // get localized not found
  const notFound = notFounds.find(h => h.node_locale === locale)

  // see: https://www.contentful.com/developers/docs/tutorials/general/rich-text-and-gatsby/
  // and: https://github.com/contentful/rich-text/tree/master/packages/rich-text-react-renderer
  /**
   * The `renderNode` keys should be one of the following `BLOCKS` and `INLINES` properties:
   *    - BLOCKS
   *      - DOCUMENT
   *      - PARAGRAPH
   *      - HEADING_1 ... HEADING_6
   *      - UL_LIST, OL_LIST, LIST_ITEM
   *      - QUOTE
   *      - HR
   *      - EMBEDDED_ENTRY, EMBEDDED_ASSET
   *    - INLINES
   *      - EMBEDDED_ENTRY
   *      - HYPERLINK, ENTRY_HYPERLINK, ASSET_HYPERLINK
   *
   * The `renderMark` keys should be one of the following `MARKS` properties:
   *    - BOLD, ITALIC, UNDERLINE, CODE
   *
   * The `renderText` callback is a function that has a single string argument and returns a
   * React node. Each text node is evaluated individually by this callback. A possible use case
   * for this is to replace instances of `\n` produced by `Shift + Enter` with `<br />` React
   * elements.
   */
  const renderOptions = {
    renderNode: {
      [BLOCKS.UL_LIST]: (_node, children) => {
        return (
          <ul style={{ textAlign: "left" }}>{children}</ul>
        )
      },
      [INLINES.ENTRY_HYPERLINK]: node => {
        let slug = ""
        if (node.data.target.pageSlug) {
          slug = node.data.target.pageSlug
        }
        if (node.data.target.collectionSlug) {
          slug = node.data.target.collectionSlug
        }
        return (
          <LocalizedLink to={`/${slug}`}>{node.content[0].value}</LocalizedLink>
        )
      },
    },
    renderText: text => {
      return smartReplacements(text, locale)
        .split("\n")
        .reduce((children, textSegment, index) => {
          return [...children, index > 0 && <br key={index} />, textSegment]
        }, [])
    },
  }

  return (
    <Layout title={formatMessage({ id: "pages.404.title" })}>
      {/* Header Image */}
      <section className="is-relative">
        <div className="container is-fluid px-0 is-parallax is-large is-fullheight">
          <DoubleBorderBox alignLeft="true">
            <h2><FormattedMessage id="pages.404.title" /></h2>
            <div className="content">
              {renderRichText(notFound.description, renderOptions)}
            </div>
          </DoubleBorderBox>
          <Parallax
            loading="eager"
            bgImage={getSrc(notFound.headerImage)}
            bgImageSrcSet={getSrcSet(notFound.headerImage)}
            bgImageAlt={formatMessage({ id: "meta.description" })}
            bgImageStyle={{ objectFit: "cover" }}
            strength={0}
          />
        </div>
      </section>

      {/* <pre>{JSON.stringify(notFound, null, 2)}</pre> */}
    </Layout>
  )
}

export default NotFoundPage

export const NotFoundPageQuery = graphql`
  query {
    allContentfulNotFound {
      nodes {
        node_locale
        headerImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            quality: 100
          )
        }
        description {
          raw
          references {
            ... on ContentfulCollection {
              contentful_id
              collectionTitle
              collectionSlug
            }
          }
        }
      }
    }
  }
`
